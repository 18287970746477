export const stepsInventoryControl = {
	config: {
		labels: {
			titleInventory: "Dê um título ao inventário",
			exampleTitleInventory: "ex: “Inventário de bebidas”",
			stockistName: "Nome do estoquista",
			selectStock: "Selecione o estoque para criar o inventário",
			selectCategory: "Selecione as categorias",
		},
		inventoryPlaceholder: "Insira o título do inventário",
		stockPlaceholder: "Clique para selecionar",
		categoryPlaceholder: "Clique para selecionar as categorias",
	},
	count: {
		labels: {
			count: "Contagem",
			unit: "Unidade",
			price: "Preço de custo",
			amount: "Valor total",
			deleteCategory: "Excluir categoria",
			deleteSelectedProducts: "Excluir selecionados",
			inventoryItems: "Itens do estoque",
			deleteItems: "Excluir itens",
			modalDescription:
				"Ao confirmar a ação você exclui permanentemente {{amount}} {{itemLabel}} do inventário. Deseja continuar?",
			modalDeleteCategoryMessage:
				"Ao confirmar a ação a categoria {{category}} será excluída permanentemente. Deseja continuar?",
			modalDeleteProductMessage:
				"Ao confirmar a ação produto {{product}} será excluída permanentemente. Deseja continuar?",
			deleteSelectedItems: "Excluir itens selecionados",
			searchProduct: "Buscar produto",
			item: {
				single: "item",
				plural: "itens",
			},
		},
		invalidCountConverted:
			"A contagem nesse campo não deve exceder, ou ser igual, a medida máxima da conversão cadastrada.",
		filter: {
			byProduct: "Organizar por porduto",
			byCategory: "Organizar por categoria",
		},
		notification: {
			success: "Itens excluídos com sucesso.",
			error: "Ocorreu um erro na exclusão dos itens. Tente novamente mais tarde.",
		},
	},
	resume: {
		tableColumns: {
			category: "Categoria",
			quantity: "Quantidade",
			difference: "Diferença",
			system: "Sistema",
			product: "Produto",
			systemQuantity: "Qtd do sistema",
			count: "Contagem",
			countDifference: "Diferença em qtd",
			monetaryDifference: "Diferença em {{currencyNarrowSymbol}}",
			unit: "Unidade",
			action: {
				label: "Ação",
				loss: "Perda",
				adjust: "Ajuste",
			},
		},
	},
	adjustLoss: {
		tableColumns: {
			category: "Categoria",
			quantity: "Quantidade",
			difference: "Diferença",
			system: "Sistema",
			product: "Produto",
			systemQuantity: "Qtd do sistema",
			count: "Contagem",
			countDifference: "Diferença em qtd",
			monetaryDifference: "Diferença em {{currencyNarrowSymbol}}",
			unit: "Unidade",
			action: {
				label: "Ação",
				loss: "Perda",
				adjust: "Ajuste",
			},
		},
	},
	approval: {
		exportExcel: "Baixar em Excel",
		inconsistencyInventory: {
			units: "Unidades",
			sumOfPositiveDifferences: "Somatório de diferenças positivas",
			sumOfNegativeDifferences: "Somatório de diferenças negativas",
			inconsistenciesByQuantity: "5 principais inconsistências por quantidade",
			inconsistenciesByDiffences: "5 principais inconsistências por valor",
			checkedProducts: "Produtos conferidos",
		},
		inputPlaceholder: "Buscar produto",
		select: {
			all: "Exibir todos os itens",
			onlyNegativeDifference: "Apenas produtos com diferença negativa",
			onlyPositiveDifference: "Apenas produtos com diferença positiva",
			onlyWithoutDifference: "Apenas produtos sem diferença",
		},
		tableColumns: {
			category: "Categoria",
			quantity: "Quantidade",
			difference: "Diferença",
			system: "Sistema",
			product: "Produto",
			systemQuantity: "Quantidade do sistema",
			count: "Contagem",
			countDifference: "Diferença em qtd",
			monetaryDifference: "Diferença em {{currencyNarrowSymbol}}",
			unit: "Unidade",
			action: {
				label: "Ação",
				loss: "Perda",
				adjust: "Ajuste",
			},
		},
		searchProduct: "Buscar produto",
	},
};
